const discount100p: string[] = [
  'dumplingsticker',
  'watergun'
]

const discount90p: string[] = [
  'fomo90',
  'endyear90pos'
]
const discount80p: string[] = [
  '50cmeyPOS',
  '60cmeyPOS',
  '70cmeyPOS',
  'gwsp80pos',
  'glwsp80pos',
  'mmsp80pos',
  '80cmeyPOS',
  'ye80pos',
  'gwmidyear80pos',
  'cmey',
  'midyear80pos',
  'fomo80',
  'endyear80pos',
  'mysale80pos',
  'gwendyear80pos',
  'mmendyear80pos'
]
const discount70p: string[] = [
  'midyear70pos',
  'gwsp70pos',
  'gwmidyear70pos',
  'gwmidyear50pos',
  'glwsp70pos',
  'mmsp70pos',
  'fomo70',
  'endyear70pos',
  'mysale70pos',
  'gwendyear70pos',
  'mmendyear70pos'
]
const discount60p: string[] = [
  'ye60pos',
  'gwsp60pos',
  'glwsp60pos',
  'mmsp60pos',
  // 'midyear60pos',
  'midyear60pos',
  'gwmidyear60pos',
  'fomo60',
  'endyear60pos',
  'mysale60pos',
  'gwendyear60pos',
  'mmendyear60pos'
]
const discount50p: string[] = [
  'terminal50',
  'mmss50pos',
  'ye50pos',
  'gwsp50pos',
  'glwsp50pos',
  'midyear50pos',
  'mmsp50pos',
  'gwmidyear40pos',
  'gwmidyear30pos',
  'midyear40pos',
  'mmbd50pos',
  'fomo50',
  'endyear50pos',
  'mysale50pos',
  'gwendyear50pos',
  'mmendyear50pos'
]
const discount40p: string[] = [
  'mmss40pos',
  'SPP40POS',
  'gwsp40pos',
  'glwsp40pos',
  'midyear30pos',
  'mmsp40pos',
  'mmbd40pos',
  'fomo40',
  'endyear40pos',
  'gwendyear40pos',
  'mmendyear40pos'
]
const discount30p: string[] = [
  'mmss30pos',
  'SPP30POS',
  'gwsp30pos',
  'glwsp30pos',
  'midyear20pos',
  'mmsp30pos',
  'mmbd30pos',
  'fomo30',
  'endyear30pos',
  'gwendyear30pos',
  'mmendyear30pos'
]
const discount20p: string[] = [
  'mmss20pos',
  'SPP20POS',
  'gwsp20pos',
  'glwsp20pos',
  'mmsp20pos',
  'fomo20',
  'mmbd20pos',
  'endyear20pos',
  'gwendyear20pos',
  'mmendyear20pos'
]
const discount10p: string[] = [
  'fomo10',
  'endyear10pos'
]
const discount85pFloor: string[] = [
  'gel'
]
const discount70pFloor: string[] = [
  'my2021',
  'mypos70',
  'mypos50',
  'mypos40',
  'mypos30',
  'mypos20',
  'ye70pos'
]

const discount50pFloor: string[] = ['sanitizer']
const discount30pFloor: string[] = []
const discount400: string[] = ['mustard']
const discountTo500: string[] = ['gwendyear500pos']

export const saleTags = {
  discount70p,
  discount50p,
  discount40p,
  discount30p,
  discount20p,
  discount85pFloor,
  discount70pFloor,
  discount50pFloor,
  discount30pFloor,
  discount400,
  discount60p,
  discount80p,
  discount90p,
  discount10p,
  discountTo500,
  discount100p
}

export const calculateStepPricing = (items: any[]): any[] => {
  let unsaleItems = []
  const saleItems = []
  let allItems: any[] = []
  for (const item of items) {
    const isTag = item.tags && item.tags.length > 0
    const isDiscount70p = isTag && discount70p.length > 0
      ? item.tags.some((tag: string) => discount70p.some((dis) => dis.toUpperCase() === tag.toUpperCase()))
      : false
    const isDiscount10p = isTag && discount10p.length > 0
      ? item.tags.some((tag: string) => discount10p.some((dis) => dis.toUpperCase() === tag.toUpperCase()))
      : false
    const isDiscount100p = isTag && discount100p.length > 0
      ? item.tags.some((tag: string) => discount100p.some((dis) => dis.toUpperCase() === tag.toUpperCase()))
      : false
    const isDiscount90p = isTag && discount90p.length > 0
      ? item.tags.some((tag: string) => discount90p.some((dis) => dis.toUpperCase() === tag.toUpperCase()))
      : false
    const isDiscount80p = isTag && discount80p.length > 0
      ? item.tags.some((tag: string) => discount80p.some((dis) => dis.toUpperCase() === tag.toUpperCase()))
      : false
    const isDiscount60p = isTag && discount60p.length > 0
      ? item.tags.some((tag: string) => discount60p.some((dis) => dis.toUpperCase() === tag.toUpperCase()))
      : false
    const isDiscount50p = isTag && discount50p.length > 0
      ? item.tags.some((tag: string) => discount50p.some((dis) => dis.toUpperCase() === tag.toUpperCase()))
      : false
    const isDiscount85pFloor = isTag && discount85pFloor.length > 0
      ? item.tags.some((tag: string) => discount85pFloor.some((dis) => dis.toUpperCase() === tag.toUpperCase()))
      : false
    const isDiscount70pFloor = isTag && discount70pFloor.length > 0
      ? item.tags.some((tag: string) => discount70pFloor.some((dis) => dis.toUpperCase() === tag.toUpperCase()))
      : false
    const isDiscount50pFloor = isTag && discount50pFloor.length > 0
      ? item.tags.some((tag: string) => discount50pFloor.some((dis) => dis.toUpperCase() === tag.toUpperCase()))
      : false
    const isDiscount30pFloor = isTag && discount30pFloor.length > 0
      ? item.tags.some((tag: string) => discount30pFloor.some((dis) => dis.toUpperCase() === tag.toUpperCase()))
      : false
    const isDiscount40p = isTag && discount40p.length > 0
      ? item.tags.some((tag: string) => discount40p.some((dis) => dis.toUpperCase() === tag.toUpperCase()))
      : false
    const isDiscount30p = isTag && discount30p.length > 0
      ? item.tags.some((tag: string) => discount30p.some((dis) => dis.toUpperCase() === tag.toUpperCase()))
      : false
    const isDiscount20p = isTag && discount20p.length > 0
      ? item.tags.some((tag: string) => discount20p.some((dis) => dis.toUpperCase() === tag.toUpperCase()))
      : false
    const isDiscount400 = isTag && discount400.length > 0
      ? item.tags.some((tag: string) => discount400.some((dis) => dis.toUpperCase() === tag.toUpperCase()))
      : false

    const isDiscountTo500 = isTag && discountTo500.length > 0
      ? item.tags.some((tag: string) => discountTo500.some((dis) => dis.toUpperCase() === tag.toUpperCase()))
      : false

    if (isDiscountTo500) {
      item.price = 500
      saleItems.push(item)
    } else if (isDiscount100p) {
      item.price = 0
      saleItems.push(item)
    } else if (isDiscount90p) {
      item.price = Math.round(parseInt(item.fullPrice) * 0.1)
      saleItems.push(item)
    } else if (isDiscount85pFloor) {
      item.price = Math.floor(parseInt(item.fullPrice) * 0.15)
      saleItems.push(item)
    } else if (isDiscount80p) {
      item.price = Math.round(parseInt(item.fullPrice) * 0.2)
      saleItems.push(item)
    } else if (isDiscount70pFloor) {
      item.price = Math.floor(parseInt(item.fullPrice) * 0.3)
      saleItems.push(item)
    } else if (isDiscount70p) {
      item.price = Math.round(parseInt(item.fullPrice) * 0.3)
      saleItems.push(item)
    } else if (isDiscount60p) {
      item.price = Math.round(parseInt(item.fullPrice) * 0.4)
      saleItems.push(item)
    } else if (isDiscount50p) {
      item.price = Math.round(parseInt(item.fullPrice) * 0.5)
      saleItems.push(item)
    } else if (isDiscount50pFloor) {
      item.price = Math.floor(parseInt(item.fullPrice) * 0.5)
      saleItems.push(item)
    } else if (isDiscount40p) {
      item.price = Math.round(parseInt(item.fullPrice) * 0.6)
      saleItems.push(item)
    } else if (isDiscount30pFloor) {
      item.price = Math.floor(parseInt(item.fullPrice) * 0.7)
      saleItems.push(item)
    } else if (isDiscount30p) {
      item.price = Math.round(parseInt(item.fullPrice) * 0.7)
      saleItems.push(item)
    } else if (isDiscount20p) {
      item.price = Math.round(parseInt(item.fullPrice) * 0.8)
      saleItems.push(item)
    } else if (isDiscount10p) {
      item.price = Math.round(parseInt(item.fullPrice) * 0.9)
      saleItems.push(item)
    } else if (isDiscount400) {
      item.price = parseInt(item.fullPrice) - 400
      saleItems.push(item)
    } else {
      item.price = parseInt(item.fullPrice)
      unsaleItems.push(item)
    }
  }

  unsaleItems = unsaleItems.sort((a, b) => (parseInt(b.price) - parseInt(a.price)))
  allItems = unsaleItems.concat(saleItems)
  const result: any[] = []

  allItems.forEach((item, i) => {
    const fullPrice = parseInt(item.price)
    result.push({
      ...allItems[i],
      price: fullPrice
    })
  })

  return result
}
